/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import Cards from 'react-credit-cards';
import * as cepPromise from 'cep-promise';

import history from '../../services/history';
import currencyFormat from '../../services/currency';

import {
  Container,
  Wrapper,
  Title,
  Price,
  Description,
  Form,
  Button,
  Genre,
  PaymentMethod,
  Installment,
} from './styles';

import PagarmeBadge from '../PagarmeBadge';

import api from '../../services/api';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  document_number: Yup.string()
    .min(12, 'O CPF precisa ter 11 dígitos')
    .required('O CPF é obrigatório'),
  phone_number: Yup.string().required('O telefone é obrigatório'),
  birthday: Yup.string().required('A data de nascimento é obrigatória'),
  genre: Yup.string().required('Selecione o sexo'),
});

export function Checkout() {
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focus, setFocus] = useState('');
  const [cardName, setCardName] = useState('');
  const [number, setNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(false);

  const [zipcode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [plan, setPlan] = useState();
  const { unidade_id, plan_id } = useParams();

  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  function handleInputFocus(e) {
    setFocus(e.target.name);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    switch (name) {
      case 'card_holder_name':
        setCardName(value);
        break;
      case 'cvc':
        setCvc(value);
        break;
      case 'expiry':
        setExpiry(value);
        break;
      case 'number':
        setNumber(value);
        break;
      case 'zipcode':
        setZipCode(value);
        break;
      case 'street':
        setStreet(value);
        break;
      case 'neighborhood':
        setNeighborhood(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'state':
        setState(value);
        break;

      default:
        break;
    }
  }

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormRequest = async (token, data) => {
    setIsLoading(true);

    if (!paymentMethod) {
      toast.error('Selecione um método de pagamento.', {
        position: 'bottom-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setIsLoading(false);

      return;
    }

    const {
      name,
      email,
      document_number,
      phone_number,
      birthday,
      number: card_number,
      card_holder_name,
      expiry: card_expiry,
      cvc: card_cvc,
      genre,
      zipcode: zipcodeData,
      neighborhood: neighborhoodData,
      street: streetData,
      street_number: streetNumberData,
    } = data;

    const isCreditCard = paymentMethod === 'credit_card';

    try {
      const response = await api.post('/subscriptions', {
        isCreditCard,
        ...(isCreditCard && {
          card_number: card_number.replace(/\D/g, ''),
          card_holder_name,
          card_expiration_date: card_expiry.replace(/\D/g, ''),
          card_cvv: card_cvc.replace(/\D/g, ''),
        }),
        plan_id,
        unidade_id,
        name,
        email,
        document_number,
        phone_number,
        birthday,
        genre,
        payment_method: paymentMethod,
        admin_email: plan.email,
        zipcode: zipcodeData,
        neighborhood: neighborhoodData,
        street: streetData,
        street_number: streetNumberData,
        token,
      });

      setIsLoading(false);

      if (response?.data?.error) {
        const errorMessage =
          response.data.error === '"params.id" must be a number'
            ? 'Falha no meio de pagamento'
            : response.data.error;

        toast.error(errorMessage, {
          position: 'bottom-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      if (paymentMethod === 'boleto') {
        const { current_transaction, manage_url } = response.data;

        history.push(`/${unidade_id}/plan/${plan_id}/boleto`, {
          name: data.name,
          email: data.email,
          boleto_url: current_transaction.boleto_url,
          boleto_barcode: current_transaction.boleto_barcode,
          boleto_expiration_date: current_transaction.boleto_expiration_date,
          manage_url,
        });

        return;
      }

      history.push(`/${unidade_id}/plan/${plan_id}/success`, {
        name: data.name,
        email: data.email,
      });
    } catch (error) {
      if (error.response.status !== 200) {
        setIsLoading(false);

        const errorMessage =
          error.response.data.message === '"params.id" must be a number'
            ? 'Pagamento recusado, tente novamente'
            : error.response.data.message;

        toast.error(errorMessage, {
          position: 'bottom-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const onSubmit = useCallback(
    async (data) => {
      if (!executeRecaptcha) return;

      executeRecaptcha('enquiryFormSubmit').then((gReCaptchaToken) => {
        handleFormRequest(gReCaptchaToken, data);
      });
    },
    [executeRecaptcha, paymentMethod]
  );

  useEffect(() => {
    if (zipcode.replace(/\D/g, '').length >= 8) {
      cepPromise(zipcode).then((response) => {
        setStreet(response.street);
        setCity(response.city);
        setNeighborhood(response.neighborhood);
        setState(response.state);
      });
    }
  }, [zipcode]);

  Wrapper.defaultProps = {
    color: 'transparent',
  };

  const oneTimePaymentDescription = useMemo(() => {
    if (!plan?.payment_methods) return '';

    const methods = plan.payment_methods;

    if (methods.includes('credit_card') && methods.includes('boleto'))
      return 'cartão de credito ou boleto';

    if (methods.includes('boleto')) return 'boleto';

    if (methods.includes('credit_card')) return 'cartão de credito';

    return '';
  }, [plan?.payment_methods]);

  useEffect(() => {
    async function fetchPlan() {
      const response = await api.get(`/plans/${plan_id}/unidade/${unidade_id}`);

      if (response.data.ativo === 0) {
        history.push('/account/disabled');

        return;
      }

      console.log();

      setPlan(response.data);
    }

    fetchPlan();
  }, [plan_id]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Assinar Plano</title>
      </Helmet>
      <Container>
        <Wrapper color={plan && plan.color}>
          <Title>
            {(plan && plan.name) || <Skeleton height={42} width={250} />}
          </Title>

          {(plan &&
            ((plan.installments > 1 && (
              <>
                <Installment>
                  {plan.installments}x{' '}
                  {currencyFormat(plan.amount / plan.installments)}
                  <span> no cartão</span>
                </Installment>
                <Price size={16}>
                  ou {currencyFormat(plan?.amount)} à vista(
                  {oneTimePaymentDescription})
                </Price>
              </>
            )) || <Price>{currencyFormat(plan?.amount)}</Price>)) || (
            <Skeleton height={42} width={94} />
          )}

          <Description>
            {plan ? (
              'Preencha os dados abaixo para finalizar sua assinatura'
            ) : (
              <Skeleton height={20} width={340} />
            )}
          </Description>

          <Form onSubmit={handleSubmit(onSubmit)}>
            {(plan && (
              <input
                name="name"
                placeholder="Seu nome"
                border={errors.name && '1px solid red'}
                ref={register({ required: true })}
              />
            )) || (
              <Skeleton
                style={{ marginBottom: 10 }}
                count={5}
                height={44}
                width="100%"
              />
            )}
            {errors.name && <p>{errors.name.message}</p>}

            {plan && (
              <input
                placeholder="Seu email"
                name="email"
                ref={register({ required: true })}
              />
            )}
            {errors.email && <p>{errors.email.message}</p>}

            {plan && (
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                ref={(ref) =>
                  ref && register(ref.inputElement, { required: true })
                }
                placeholder="Seu CPF ou passaporte internacional"
                name="document_number"
              />
            )}
            {errors.document_number && <p>{errors.document_number.message}</p>}

            {plan && (
              <MaskedInput
                mask={[
                  '+',
                  /\d/,
                  /\d/,
                  ' ',
                  '(',
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="Seu número de contato com DDD e DDI"
                name="phone_number"
                ref={(ref) =>
                  ref && register(ref.inputElement, { required: true })
                }
              />
            )}
            {errors.phone_number && <p>{errors.phone_number.message}</p>}

            {plan && (
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="Sua data de nascimento"
                name="birthday"
                ref={(ref) =>
                  ref && register(ref.inputElement, { required: true })
                }
              />
            )}
            {errors.birthday && <p>{errors.birthday.message}</p>}

            {plan && (
              <Genre>
                <strong>Sexo</strong>
                <label htmlFor="male">
                  Masculino
                  <input
                    ref={register({ required: true })}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    type="radio"
                    name="genre"
                    id="male"
                    value="m"
                    defaultChecked
                  />
                </label>
                <label htmlFor="female">
                  Feminino
                  <input
                    ref={register({ required: true })}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    type="radio"
                    name="genre"
                    id="female"
                    value="f"
                  />
                </label>
              </Genre>
            )}
            {errors.genre && <p>{errors.genre.message}</p>}

            {plan && (
              <PaymentMethod>
                <strong>Método de pagamento</strong>

                {(plan.payment_methods.length > 1 && (
                  <>
                    <Button
                      onClick={() => setPaymentMethod('boleto')}
                      type="button"
                      color="#59bc63"
                      active={paymentMethod === 'boleto' ? 'active' : ''}
                    >
                      {plan.installments > 1 ? 'Boleto à vista' : 'Boleto'}
                    </Button>
                    <Button
                      onClick={() => setPaymentMethod('credit_card')}
                      type="button"
                      color="#354edb"
                      active={
                        paymentMethod === 'credit_card' ? 'credit_card' : ''
                      }
                    >
                      {plan.installments > 1
                        ? `${plan.installments}x no Cartão de crédito`
                        : 'Cartão de crédito'}
                    </Button>
                  </>
                )) || (
                  <Button
                    onClick={() => setPaymentMethod(plan.payment_methods[0])}
                    type="button"
                    color="#59bc63"
                    active={
                      paymentMethod === plan.payment_methods[0] ? 'active' : ''
                    }
                  >
                    Avançar (
                    {plan.payment_methods[0] === 'boleto'
                      ? 'Boleto'
                      : 'Cartão de crédito'}
                    )
                  </Button>
                )}
              </PaymentMethod>
            )}

            {plan &&
              (paymentMethod === 'credit_card' ||
                paymentMethod === 'boleto') && (
                <>
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                    placeholder="CEP"
                    name="zipcode"
                    onChange={handleInputChange}
                    ref={(ref) =>
                      ref && register(ref.inputElement, { required: true })
                    }
                  />

                  <input
                    type="text"
                    value={neighborhood}
                    name="neighborhood"
                    placeholder="Bairro"
                    onChange={handleInputChange}
                    ref={register({ required: true })}
                  />

                  <input
                    type="text"
                    name="street"
                    value={street}
                    placeholder="Rua"
                    onChange={handleInputChange}
                    ref={register({ required: true })}
                  />

                  <input
                    type="text"
                    name="street_number"
                    placeholder="Número"
                    onChange={handleInputChange}
                    ref={register({ required: true })}
                  />

                  <input
                    type="text"
                    name="city"
                    value={city}
                    placeholder="Cidade"
                    onChange={handleInputChange}
                    ref={register({ required: true })}
                  />

                  <input
                    type="text"
                    name="state"
                    value={state}
                    placeholder="Estado"
                    onChange={handleInputChange}
                    ref={register({ required: true })}
                  />
                </>
              )}

            {plan && paymentMethod === 'credit_card' && (
              <>
                <p className="acceptedBrands">
                  Aceitamos cartões Mastercard, Visa, AMEX, JCB, Elo, Hipercard,
                  Diners, Aura e Discover
                </p>

                <div id="credit-card-payment">
                  <Cards
                    cvc={cvc}
                    expiry={expiry}
                    focused={focus}
                    name={cardName}
                    number={number}
                    placeholders={{ name: 'SEU NOME AQUI' }}
                    locale={{ valid: 'data exp' }}
                  />

                  <MaskedInput
                    placeholderChar="•"
                    mask={[
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="Número do cartão"
                    name="number"
                    ref={(ref) =>
                      ref && register(ref.inputElement, { required: true })
                    }
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />

                  {errors.number && <p>{errors.number.message}</p>}

                  <input
                    type="text"
                    name="card_holder_name"
                    placeholder="Nome (como escrito no cartão)"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    ref={register({ required: true })}
                  />

                  <MaskedInput
                    mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                    type="tel"
                    name="expiry"
                    placeholder="Data de expiração (MM/AA)"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    ref={(ref) =>
                      ref && register(ref.inputElement, { required: true })
                    }
                  />

                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    type="tel"
                    guide={false}
                    name="cvc"
                    placeholder="Código de segurança"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    ref={(ref) =>
                      ref && register(ref.inputElement, { required: true })
                    }
                  />
                </div>
              </>
            )}

            {paymentMethod && (
              <Button disabled={isLoading} type="submit">
                {isLoading ? 'Fazendo assinatura...' : 'Assinar'}
              </Button>
            )}

            <Link to={`/${plan && plan.unidade_id}`}>Voltar</Link>
          </Form>
          <PagarmeBadge />
        </Wrapper>
      </Container>
    </>
  );
}
